import { useState } from "react";
import { Stack, TextField, Box, Tooltip, InputAdornment, IconButton } from "@mui/material";
import { Text } from "@clipboard-health/ui-react";
import { FormControlLabel } from "@mui/material";
import { calculatePayBoost, VALID_CHARGE_REGEX } from "./utils";
import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material";
import { isDefined } from "@clipboard-health/util-ts";
import pluralize from "pluralize";

interface ShiftInviteCustomRatesProps {
  onChange: (newValue: number | undefined) => void;
  customRateChargeBoost: number | undefined;
  inviteCounts: number;
}

const MAX_CUSTOM_RATE_BOOST = 99.99;

export function ShiftInviteCustomRates(params: ShiftInviteCustomRatesProps) {
  const { onChange, customRateChargeBoost, inviteCounts } = params;
  const [additionalCharge, setAdditionalCharge] = useState<string>(
    customRateChargeBoost?.toFixed(2) ?? ""
  );

  const convertAdditionalChargeToCustomRate = (charge: string): number | undefined => {
    const parsedValue = parseFloat(charge);
    return Number.isNaN(parsedValue) || parsedValue === 0 ? undefined : parsedValue;
  };

  return (
    <Stack alignItems="flex-start" spacing={1}>
      <Stack sx={{ width: "100%", maxWidth: 600, mt: 2 }} spacing={2}>
        <Text bold>
          Custom Rates - Add an hourly bonus per invite{" "}
          {inviteCounts > 0 && isDefined(customRateChargeBoost) && customRateChargeBoost > 0
            ? ` (${inviteCounts} ${pluralize("invite", inviteCounts)})`
            : ""}
        </Text>
        <Stack direction="row" alignItems="center" spacing={2}>
          <FormControlLabel
            control={
              <TextField
                fullWidth
                label="Additional hourly charge"
                placeholder="-"
                value={additionalCharge}
                onChange={(event) => {
                  const newValue = event.target.value;
                  if (VALID_CHARGE_REGEX.test(newValue)) {
                    setAdditionalCharge(newValue);
                    onChange(convertAdditionalChargeToCustomRate(newValue));
                  }
                }}
                onBlur={() => {
                  if (additionalCharge !== "") {
                    const newValue = parseFloat(additionalCharge);
                    setAdditionalCharge(newValue === 0 ? "" : newValue.toFixed(2));
                  }
                }}
                inputProps={{
                  inputMode: "decimal",
                  pattern: "[0-9]*(.[0-9]{0,2})?",
                  min: 0,
                  max: 100,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Stack direction="column" spacing={0}>
                        <IconButton
                          onClick={() => {
                            const currentValue =
                              additionalCharge === "" ? 0 : parseFloat(additionalCharge);
                            const newValue = Math.min(
                              currentValue + 1,
                              MAX_CUSTOM_RATE_BOOST
                            ).toFixed(2);
                            setAdditionalCharge(newValue);
                            onChange(parseFloat(newValue));
                          }}
                          edge="end"
                          size="small"
                          sx={{ height: "20px", width: "20px" }}
                        >
                          <KeyboardArrowUp fontSize="small" />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            const currentValue =
                              additionalCharge === "" ? 0 : parseFloat(additionalCharge);
                            const newValueNumber = Math.max(currentValue - 1, 0);
                            const newValue = newValueNumber === 0 ? "" : newValueNumber.toFixed(2);
                            setAdditionalCharge(newValue);
                            onChange(convertAdditionalChargeToCustomRate(newValue));
                          }}
                          edge="end"
                          size="small"
                          sx={{ height: "20px", width: "20px" }}
                        >
                          <KeyboardArrowDown fontSize="small" />
                        </IconButton>
                      </Stack>
                    </InputAdornment>
                  ),
                }}
              />
            }
            label=""
            sx={{ flex: 1 }}
          />
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ mx: 1 }}>
            <Text>→</Text>
          </Box>
          <FormControlLabel
            control={
              <Tooltip
                title="A service fee is applied to the pay boost"
                componentsProps={{
                  tooltip: {
                    sx: {
                      margin: "2px !important",
                    },
                  },
                }}
                placement="bottom-end"
              >
                <TextField
                  fullWidth
                  label="Hourly bonus for the professional"
                  placeholder="-"
                  value={calculatePayBoost(additionalCharge)}
                  InputProps={{
                    readOnly: true,
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  sx={{
                    bgcolor: "action.disabledBackground",
                    borderRadius: 1,
                  }}
                />
              </Tooltip>
            }
            label=""
            sx={{ flex: 1 }}
          />
        </Stack>
      </Stack>
      {isDefined(customRateChargeBoost) && customRateChargeBoost >= 15 && (
        <Text variant="subtitle2" color={(theme) => theme.palette.warning.main}>
          Please note that the rates above are the invite's <b>extra</b> charge and <b>extra</b>{" "}
          pay. They will be added to the shift's base rates.
        </Text>
      )}
    </Stack>
  );
}
